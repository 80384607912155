import React from "react"

import "./DecoderNIP.scss"


function DecoderNIP({ props }){

  return(  
    <div className="DecoderNIP flex stretch end wrap">
      DecoderNIP
    </div>
  )
}

export default DecoderNIP